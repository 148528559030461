@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100&family=Mulish:wght@300&family=PT+Serif&display=swap");

body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Mulish", sans-serif;
  font-family: "PT Serif", serif;
}

.Toastify__toast-container {
  width: 800px !important;
  z-index: 9999999999999 !important;
}

.Toastify__toast {
  border-radius: 8px !important;
  box-shadow: none !important;
}

.Toastify__toast.Toastify__toast--success {
  border: 1px solid #2ccb8d;
  background-color: #f8fffc;
}

.Toastify__toast.Toastify__toast--error {
  border: 1px solid #f6133d;
  background-color: #ffeef1;
}

.Toastify__toast.Toastify__toast--info {
  border: 1px solid #04b0ed;

  background-color: #f4fcff;
}

.Toastify__toast-body {
  margin: 0 !important;
}

.Toastify__toast-body div:last-child {
  font-size: 14px;
  padding-left: 8px;
}

.Toastify__toast-body div:first-child {
  width: 16px;
}

.Toastify__close-button {
  align-self: center !important;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #e77228 !important;
}
.css-k4qjio-MuiFormHelperText-root.Mui-error {
  color: #e77228 !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 999999 !important;
}

.css-10hburv-MuiTypography-root {
  font-family: "Mulish", sans-serif !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1ygcj2i-MuiTableCell-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Mulish", sans-serif !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-family: "Mulish", sans-serif !important;
}

.css-8m5rrr-MuiButtonBase-root-MuiChip-root {
  font-family: "Mulish", sans-serif !important;
}

.css-u4tvz2-MuiFormLabel-root {
  font-family: "Mulish", sans-serif !important;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "Mulish", sans-serif !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  font-family: "Mulish", sans-serif !important;
}

.css-gavykb-MuiChip-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1vzxghv-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  font-family: "Mulish", sans-serif !important;
}

.css-k4qjio-MuiFormHelperText-root {
  font-family: "Mulish", sans-serif !important;
}

.css-1d1r5q-MuiFormHelperText-root {
  font-family: "Mulish", sans-serif !important;
}

.css-7v2qeo-MuiFormControl-root-MuiTextField-root input {
  font-family: "Mulish", sans-serif !important;
}

.rti--container {
  min-height: 50px !important;
  background-color: #f8f8f8 !important;
  border: none !important;
}

.rti--input {
  background-color: #f8f8f8 !important;
  border: none !important;
}

.rti--container:focus-within {
  box-shadow: none !important;
}

